body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #303030;
    color: #FFFFFF;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.u-section-1 {
    background-image: url("images/unknown.png");
}

.u-section-1 .u-sheet-1 {
    min-height: 500px;
}

.u-section-1 .u-text-1 {
    font-family: "Alegreya Sans SC";
    text-shadow: 0 2px 8px rgba(0, 0, 0, 1);
    margin: 439px auto 9px;
}

@media (max-width: 1199px) {
    .u-section-1 .u-sheet-1 {
        min-height: 412px;
    }
}

@media (max-width: 991px) {
    .u-section-1 .u-sheet-1 {
        min-height: 316px;
    }
}

@media (max-width: 767px) {
    .u-section-1 .u-sheet-1 {
        min-height: 237px;
    }
}

@media (max-width: 575px) {
    .u-section-1 .u-sheet-1 {
        min-height: 149px;
    }
}

.u-section-2 {
    background-image: none;
}

.u-section-2 .u-sheet-1 {
    min-height: 596px;
}

.u-section-2 .u-text-1 {
    font-family: "Alegreya Sans SC";
    text-shadow: 0 2px 8px rgba(0, 0, 0, 1);
    text-align: start;
    margin: 35px auto 0;
}

.u-section-2 .u-gallery-1 {
    margin-top: 42px;
    margin-bottom: 60px;
    height: 375px;
}

.u-section-2 .u-gallery-item-1 {
    margin-right: 10px;
}

.u-section-2 .u-back-image-1 {
    width: 375px;
}

.u-section-2 .u-over-slide-1 {
    background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));
    padding: 20px;
}

.u-section-2 .u-gallery-item-2 {
    margin-right: 10px;
}

.u-section-2 .u-back-image-2 {
    width: 375px;
}

.u-section-2 .u-over-slide-2 {
    background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));
    padding: 20px;
}

.u-section-2 .u-gallery-item-3 {
    margin-right: 10px;
}

.u-section-2 .u-back-image-3 {
    width: 375px;
}

.u-section-2 .u-over-slide-3 {
    background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));
    padding: 20px;
}

.u-section-2 .u-gallery-item-4 {
    margin-right: 10px;
}

.u-section-2 .u-back-image-4 {
    width: 375px;
}

.u-section-2 .u-over-slide-4 {
    background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));
    padding: 20px;
}

.u-section-2 .u-gallery-nav-1 {
    position: absolute;
    left: 10px;
    width: 40px;
    height: 40px;
}

.u-section-2 .u-gallery-nav-2 {
    position: absolute;
    right: 10px;
    width: 40px;
    height: 40px;
}

@media (max-width: 575px) {
    .u-section-2 .u-gallery-1 {
        margin-top: 61px;
    }
}